import React from "react";
import SpeedCheck from "./speedCheck";
import SoundCheck from "./soundCheck";
import DetectSystem from "./detectSystem";
import CameraTest from "./cameraTest";
import { Link } from "react-router-dom";

export default function SystemCheck() {
  return (
    <div className="w-100">
      <h1 className="mt-5 mb-3">System Check</h1>
      <div className="w-100">
        <div className="row">
          <div className="col-md-6 mb-3">
            <div className="bg-light shadow-sm py-4">
              <h5 className="mb-3 px-3" id="testStatus">
                Running System Check…
              </h5>
              <div className="p-3 border-bottom text-start">
                <span className="d-flex justify-content-between">
                  <h6 className="fw-bold mb-3">Internet speed</h6>
                  <Link className="text-dark" to="/speed-increase">
                    Troubleshoot
                  </Link>
                </span>
                <SpeedCheck />
              </div>
              <DetectSystem />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="bg-light shadow-sm py-4">
              <h5 className="px-3 mb-3">Test Your Audio & Video</h5>
              <SoundCheck />
              <CameraTest />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
