import React, { Component } from "react";
import axios from "axios";
import config from "../../config.json";

class Meeting extends Component {
  state = {
    whereBy: "",
  };
  async componentDidMount() {
    const { data: whereBy } = await axios.get(config.whereBy);
    this.setState({ whereBy });
  }

  render() {
    const meetingRoom = this.state.whereBy.room ? (
      <iframe
        src={this.state.whereBy.room}
        id="whereByIframe"
        allow="camera; microphone; fullscreen; speaker; display-capture"
      ></iframe>
    ) : (
      ""
    );
    if (this.props.start)
      return (
        <div id="meeting" className="d-block mt-2">
          {meetingRoom}
        </div>
      );
    else return <React.Fragment></React.Fragment>;
  }
}

export default Meeting;
