import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";

//Pages
import SystemCheck from "./components/SystemCheck/systemCheck";
import NotFound from "./components/notFound";

//Articles
import IncreaseSpeed from "./components/Articles/IncreaseSpeed/IncreaseSpeed";
import OperatingSystem from "./components/Articles/OperatingSystem/OperatingSystem";
import InternetBrowser from "./components/Articles/InternetBrowser/InternetBrowser";
import SoundCheckTroubleshooting from "./components/Articles/SoundCheckTroubleshooting/SoundCheckTroubleshooting";
import WebcamCheckTroubleshooting from "./components/Articles/WebcamCheckTroubleshooting/WebcamCheckTroubleshooting";

function App() {
  return (
    <div>
      <NavBar />
      <div className="min-vh-50 container d-flex flex-column align-items-center mt-5">
        <Routes>
          <Route path="/" element={<SystemCheck />} />
          <Route path="/test" element={<SystemCheck />} />
          <Route path="/speed-increase" element={<IncreaseSpeed />} />
          <Route path="/operating-system" element={<OperatingSystem />} />
          <Route path="/internet-browser" element={<InternetBrowser />} />
          <Route path="/sound-check" element={<SoundCheckTroubleshooting />} />
          <Route
            path="/webcam-check"
            element={<WebcamCheckTroubleshooting />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
