import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <Navbar className="w-100" bg="dark" expand="lg" variant="dark" fixed="top">
      <Container>
        <Link className="navbar-brand" to="/">
          <img
            src="images/ee-white.png"
            height="50"
            className="d-inline-block align-top"
            alt="Empowered Education"
          />
        </Link>
        <Navbar.Toggle className="ma" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Troubleshooting" id="basic-nav-dropdown">
              <Link className="dropdown-item" to="/speed-increase">
                How to Increase Speed
              </Link>
              <Link className="dropdown-item" to="/operating-system">
                Operating System
              </Link>
              <Link className="dropdown-item" to="/internet-browser">
                Internet Browser Information
              </Link>
              <Link className="dropdown-item" to="/sound-check">
                Sound Check Troubleshooting
              </Link>
              <Link className="dropdown-item" to="/webcam-check">
                Webcam Check Troubleshooting
              </Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
