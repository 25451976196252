import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faHourglass } from "@fortawesome/free-solid-svg-icons";

const ResultIcon = (props) => {
  if (props.result === "success") {
    return <FontAwesomeIcon icon={faCheck} className="text-success me-2" />;
  } else if (props.result === "danger") {
    return <FontAwesomeIcon icon={faTimes} className="text-danger me-2" />;
  } else {
    return (
      <FontAwesomeIcon icon={faHourglass} spin className="text-info me-2" />
    );
  }
};
export default ResultIcon;
