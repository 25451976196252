import React from "react";
import Alert from "react-bootstrap/Alert";
import ProgressBar from "react-bootstrap/ProgressBar";
import NetworkSpeed from "network-speed";
import ResultIcon from "./common/resultIcon";

class SpeedCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      download: "Checking download speed, please wait...",
      downloadValidation: "info",
      downloadProgress: 0,
      results: [],
      upload: "Waiting for the download test to finish",
      uploadValidation: "primary",
    };
  }

  componentDidMount() {
    //Current state
    const current = this;
    getNetworkDownloadSpeed(current);

    //Create random string
    function makeid(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }

    function findMiddle(arr) {
      let sum = 0;
      for (const element of arr) {
        sum += parseFloat(element);
      }
      let middle = sum / arr.length;
      return middle.toFixed(2);
    }

    async function getNetworkUploadSpeed() {
      current.setState({
        upload: "Checking upload speed, please wait...",
        uploadValidation: "info",
      });
      const testNetworkSpeed = new NetworkSpeed();
      const options = {
        hostname: "thelearninghub.empowerededu.org",
        port: "",
        path: "/upload-test",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      };
      const fileSizeInBytes = 2000000;
      const speed = await testNetworkSpeed.checkUploadSpeed(
        options,
        fileSizeInBytes
      );
      // console.log(speed);
      if (speed.mbps < 0.6) {
        current.setState({
          uploadValidation: "danger",
        });
      } else {
        current.setState({
          uploadValidation: "success",
        });
      }

      if (speed.mbps > 0.6) {
        current.setState({
          upload: "Your upload speed is OK",
        });
      } else {
        current.setState({
          upload: "Your upload speed is low",
        });
      }

      if (
        current.state.downloadValidation === "success" &&
        current.state.uploadValidation === "success"
      ) {
        document.getElementById("testStatus").textContent =
          "System Check is complete";
      }
    }

    async function downloadTest(size, current, tests) {
      let testNetworkSpeed = new NetworkSpeed();
      let cacheID = makeid(5);
      let baseUrl =
        "https://thelearninghub.empowerededu.org/sites/default/files/downloadtests/test_" +
        size +
        ".bin?uncache=" +
        cacheID;
      let fileSizeInBytes = size * 1000;
      let progressStep = 100 / tests;
      let progress = current.state.downloadProgress;
      let currentProgress = progress + progressStep;

      // let baseUrl = 'https://cdn.speedof.me/sf/sample1024k.bin?r='+cacheID;
      // let fileSizeInBytes = 1024000;
      let speed = await testNetworkSpeed.checkDownloadSpeed(
        baseUrl,
        fileSizeInBytes
      );
      current.setState({ results: [...current.state.results, speed.mbps] });
      current.setState({
        downloadProgress: currentProgress,
      });
      if (current.state.results.length === tests) {
        let results = findMiddle(current.state.results);
        if (results > 0.6) {
          current.setState({
            download: "Your download speed is OK",
            downloadValidation: "success",
          });
        } else {
          current.setState({
            download: "Your download speed is low",
            downloadValidation: "danger",
          });
        }
        if (current.state.downloadValidation === "success") {
          getNetworkUploadSpeed();
        }
      }
    }

    async function getNetworkDownloadSpeed(current) {
      const tests = 10;
      let step = 64;
      for (let i = 1; i <= tests; i++) {
        step = step + step;
        await downloadTest(step, current, tests);
      }
    }
  }

  componentWillUnmount() {
    this.setState({
      download: "Error",
      validation: "danger",
    });
  }

  render() {
    return (
      <div>
        <Alert variant={this.state.downloadValidation}>
          <ResultIcon result={this.state.downloadValidation} />
          <small>{this.state.download}</small>
          <ProgressBar
            className="mt-2"
            variant={this.state.downloadValidation}
            now={this.state.downloadProgress}
          />
        </Alert>
        <Alert variant={this.state.uploadValidation}>
          <ResultIcon result={this.state.uploadValidation} />
          <small>{this.state.upload}</small>
        </Alert>
        <small>
          <p>
            Disclaimer: Internet speed results gathered here reflect the
            conection speed from your current internet connection and location
            to The Learning Hub servers ONLY and does NOT represent your total
            internet bandwidth as advertised by your internet provider.
          </p>
        </small>
      </div>
    );
  }
}
export default SpeedCheck;
