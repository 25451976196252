import React from "react";

export default function InternetBrowser() {
  return (
    <div className="w-100 text-start">
      <h1 className="mt-5 mb-3">Internet Browser Information</h1>
      <div className="article-body">
        To attend or host a webinar you'll need to be on the latest version of
        Chrome, Firefox, Safari, Edge or Opera.{" "}
        <span>Attendees can enter on Internet Explorer, hosts cannot.*</span>
      </div>
      <div className="article-body">
        <strong>
          <strong>
            <em>
              The Learning Hub strongly recommends hosts and presenters use
              Chrome or Firefox for the best possible experience.
            </em>
          </strong>
        </strong>
      </div>
      <div className="article-body wysiwyg-text-align-left">
        <p>
          <span className="wysiwyg-font-size-large">
            <strong>Update or Download browsers here</strong>:
          </span>
        </p>
        <p>
          <a
            href="https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&amp;hl=en"
            target="_blank"
            rel="noreferrer"
          >
            <strong>Update Chrome (Recommended)</strong>
          </a>
        </p>
        <p>
          <strong>
            <a
              href="https://support.mozilla.org/en-US/kb/update-firefox-latest-version"
              target="_blank"
              rel="noreferrer"
            >
              Update Firefox (Recommended)
            </a>
          </strong>
        </p>
        <p>
          <a
            href="https://support.apple.com/en-us/HT204416"
            target="_blank"
            rel="noreferrer"
          >
            <strong>Update Safari</strong>
          </a>
        </p>
        <p>
          <a
            href="https://www.microsoft.com/en-us/edge"
            target="_blank"
            rel="noreferrer"
          >
            <strong>
              Update Edge (If possible please replace Internet Explorerand
              legacy versions of Edge)*
            </strong>
          </a>
        </p>
        <p>
          <a href="https://www.opera.com/" target="_blank" rel="noreferrer">
            <strong>Update Opera</strong>
          </a>
        </p>
        <p></p>
        <p>
          <a href="https://support.apple.com/en-us/HT204204" target="_self">
            <strong>
              <em>To update Safari on iOS, please click here.</em>
            </strong>
          </a>
        </p>
        <p>
          <a
            href="https://chromestatus.com/features/schedule"
            target="_blank"
            rel="noreferrer"
          >
            <strong>
              <em>Keep track of Chrome's releases and updates here</em>
            </strong>
          </a>
        </p>
        <p className="wysiwyg-text-align-left"></p>
        <p className="wysiwyg-text-align-left">
          There are many reasons why you should update your browser regularly
          including:
        </p>
        <ol>
          <li>Increased security benefits</li>
          <li>New versions are faster</li>
          <li>Avoid compatibility problems associated with older versions</li>
          <li>Allow your applications to work more smoothly and efficiently</li>
          <li>Allow access to the newest applications</li>
          <li>Many new websites function only in newer browser versions</li>
          <li>Fix issues associated with previous versions</li>
        </ol>
        <p>
          <strong>
            *
            <em>
              <a
                href="https://blogs.windows.com/windowsexperience/2021/05/19/the-future-of-internet-explorer-on-windows-10-is-in-microsoft-edge/"
                target="_blank"
                rel="noreferrer"
              >
                IE is no longer supported by Microsoft and is not safe to use.
              </a>{" "}
              Internet Explorer 11 can only be used by attendees. Hosts cannot
              enter on Internet Explorer.
            </em>
          </strong>
        </p>
      </div>
    </div>
  );
}
