import React from "react";
import { Link } from "react-router-dom";
import Sound from "./common/sound";
import Microphone from "./common/microphone";

class SoundCheck extends React.Component {
  state = {
    soundTest: false,
    microphoneTest: false,
  };

  testHandler = (test) => {
    const subject = test.target.id;
    const testCase = this.state[subject] ? false : true;
    this.setState({ [subject]: testCase });
  };

  render() {
    return (
      <div className="p-3 border-bottom text-start">
        <span className="d-flex justify-content-between">
          <h6 className="fw-bold">Sound Check</h6>
          <Link className="text-dark" to="/sound-check">
            Troubleshoot
          </Link>
        </span>
        <small>
          <p className="">
            See if your microphone and headphones are setup properly
          </p>
          <p>
            For the Microphone test, a prompt will appear asking for permission
            to access your select output device
          </p>
          <p>If you are not using headphones, it'll have a loop feedback</p>
        </small>
        <button
          className="btn btn-primary mb-2 me-2"
          id="microphoneTest"
          type="button"
          onClick={this.testHandler}
        >
          {this.state.microphoneTest ? "Stop Testing" : "Test Microphone"}
        </button>
        <button
          className="btn btn-secondary mb-2 me-2"
          id="soundTest"
          type="button"
          onClick={this.testHandler}
        >
          {this.state.soundTest ? "Stop Testing" : "Test Headphone/Speakers"}
        </button>
        <Sound start={this.state.soundTest} />
        <Microphone start={this.state.microphoneTest} />
      </div>
    );
  }
}
export default SoundCheck;
