import React, { Component } from "react";

class Sound extends Component {
  render() {
    if (this.props.start)
      return (
        <div id="sound" className="d-block mt-2">
          <audio controls autoPlay src="/sound-test.mp3" />
          <p>You should hear running water</p>
        </div>
      );
    else return <React.Fragment></React.Fragment>;
  }
}

export default Sound;
