import React, { Component } from "react";
// import axios from "axios";
// import config from "../../config.json";

class Microphone extends Component {
  handleSuccess = (stream) => {
    const audio = document.getElementById("mic-test");
    audio.controls = true;
    audio.autoplay = true;
    window.stream = stream;
    audio.srcObject = stream;

    stream.oninactive = function () {
      console.log("Stream ended");
    };
  };

  handleError = (e) => {
    console.log("ruin", e.message);
  };

  render() {
    const constraints = (window.constraints = {
      audio: true,
      video: false,
    });

    if (this.props.start) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(this.handleSuccess)
        .catch(this.handleError);
      return (
        <div id="microphone" className="d-block mt-2">
          <audio controls autoPlay id="mic-test" />
          <p>You should hear your voice or your surroundings</p>
        </div>
      );
    } else {
      if (window.stream) {
        window.stream.getAudioTracks().forEach((track) => track.stop());
        window.stream = null;
      }
      return <React.Fragment></React.Fragment>;
    }
  }
}

export default Microphone;
