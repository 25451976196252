import React from "react";

const NotFound = () => {
  return (
    <div className="w-100 text-start">
      <h1 className="my-5">Page not found</h1>
    </div>
  );
};

export default NotFound;
