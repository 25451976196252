import React from "react";
import { Link } from "react-router-dom";
import Meeting from "./common/meeting";

class CameraTest extends React.Component {
  state = {
    meetingTest: false,
  };

  meetingHandler = () => {
    const meetingTest = this.state.meetingTest ? false : true;
    this.setState({ meetingTest });
  };

  render() {
    return (
      <div className="p-3 text-start">
        <span className="d-flex justify-content-between">
          <h6 className="fw-bold">Meeting Test</h6>
          <Link className="text-dark" to="/sound-check">
            Troubleshoot
          </Link>
        </span>
        <small>
          <p className="">See if your system is ready to join meetings</p>
        </small>
        <button
          className="btn btn-primary mb-2 me-2"
          id="meetingTest"
          type="button"
          onClick={this.meetingHandler}
        >
          {this.state.meetingTest ? "Stop Testing" : "Test Meeting"}
        </button>
        <Meeting start={this.state.meetingTest} />
      </div>
    );
  }
}
export default CameraTest;
